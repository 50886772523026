:root {
  --rv-cell-font-size: var(--rv-font-size-md);
  --rv-cell-line-height: 24px;
  --rv-cell-vertical-padding: 10px;
  --rv-cell-horizontal-padding: var(--rv-padding-md);
  --rv-cell-text-color: var(--rv-text-color);
  --rv-cell-background-color: var(--rv-white);
  --rv-cell-border-color: var(--rv-border-color);
  --rv-cell-active-color: var(--rv-active-color);
  --rv-cell-required-color: var(--rv-danger-color);
  --rv-cell-label-color: var(--rv-gray-6);
  --rv-cell-label-font-size: var(--rv-font-size-sm);
  --rv-cell-label-line-height: var(--rv-line-height-sm);
  --rv-cell-label-margin-top: var(--rv-padding-base);
  --rv-cell-value-color: var(--rv-gray-6);
  --rv-cell-icon-size: 16px;
  --rv-cell-right-icon-color: var(--rv-gray-6);
  --rv-cell-large-vertical-padding: var(--rv-padding-sm);
  --rv-cell-large-title-font-size: var(--rv-font-size-lg);
  --rv-cell-large-label-font-size: var(--rv-font-size-md);
  --rv-cell-group-background-color: var(--rv-white);
  --rv-cell-group-title-color: var(--rv-gray-6);
  --rv-cell-group-title-padding: var(--rv-padding-md) var(--rv-padding-md) var(--rv-padding-xs);
  --rv-cell-group-title-font-size: var(--rv-font-size-md);
  --rv-cell-group-title-line-height: 16px;
  --rv-cell-group-inset-padding: 0 var(--rv-padding-md);
  --rv-cell-group-inset-border-radius: var(--rv-border-radius-lg);
  --rv-cell-group-inset-title-padding: var(--rv-padding-md) var(--rv-padding-md) var(--rv-padding-xs) var(--rv-padding-xl);
}
.rv-cell {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: var(--rv-cell-vertical-padding) var(--rv-cell-horizontal-padding);
  overflow: hidden;
  color: var(--rv-cell-text-color);
  font-size: var(--rv-cell-font-size);
  line-height: var(--rv-cell-line-height);
  background-color: var(--rv-cell-background-color);
}
.rv-cell::after {
  position: absolute;
  box-sizing: border-box;
  content: ' ';
  pointer-events: none;
  right: var(--rv-padding-md);
  bottom: 0;
  left: var(--rv-padding-md);
  border-bottom: 1px solid var(--rv-cell-border-color);
  transform: scaleY(0.5);
}
.rv-cell:last-child::after,
.rv-cell--borderless::after {
  display: none;
}
.rv-cell__label {
  margin-top: var(--rv-cell-label-margin-top);
  color: var(--rv-cell-label-color);
  font-size: var(--rv-cell-label-font-size);
  line-height: var(--rv-cell-label-line-height);
}
.rv-cell__title,
.rv-cell__value {
  flex: 1;
}
.rv-cell__value {
  position: relative;
  overflow: hidden;
  color: var(--rv-cell-value-color);
  text-align: right;
  vertical-align: middle;
  word-wrap: break-word;
}
.rv-cell__value--alone {
  color: var(--rv-text-color);
  text-align: left;
}
.rv-cell__left-icon,
.rv-cell__right-icon {
  height: var(--rv-cell-line-height);
  font-size: var(--rv-cell-icon-size);
  line-height: var(--rv-cell-line-height);
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.rv-cell__left-icon {
  margin-right: var(--rv-padding-xs);
}
.rv-cell__right-icon {
  margin-left: var(--rv-padding-xs);
  color: var(--rv-cell-right-icon-color);
}
.rv-cell--clickable {
  cursor: pointer;
}
.rv-cell--clickable:active {
  background-color: var(--rv-cell-active-color);
}
.rv-cell--required {
  overflow: visible;
}
.rv-cell--required::before {
  position: absolute;
  left: var(--rv-padding-xs);
  color: var(--rv-cell-required-color);
  font-size: var(--rv-cell-font-size);
  content: '*';
}
.rv-cell--center {
  align-items: center;
}
.rv-cell--large {
  padding-top: var(--rv-cell-large-vertical-padding);
  padding-bottom: var(--rv-cell-large-vertical-padding);
}
.rv-cell--large .rv-cell__title {
  font-size: var(--rv-cell-large-title-font-size);
}
.rv-cell--large .rv-cell__label {
  font-size: var(--rv-cell-large-label-font-size);
}
.rv-cell-group {
  background-color: var(--rv-cell-group-background-color);
}
.rv-cell-group--inset {
  margin: var(--rv-cell-group-inset-padding);
  overflow: hidden;
  border-radius: var(--rv-cell-group-inset-border-radius);
}
.rv-cell-group__title {
  padding: var(--rv-cell-group-title-padding);
  color: var(--rv-cell-group-title-color);
  font-size: var(--rv-cell-group-title-font-size);
  line-height: var(--rv-cell-group-title-line-height);
}
.rv-cell-group__title--inset {
  padding: var(--rv-cell-group-inset-title-padding);
}
